import React from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection } from 'firebase/firestore'
import { firestore } from './firebase';
export default function Contact() {
  document.body.style.backgroundColor ='#33F3FF'
  function Submit(){
    if(document.getElementById('name').value !== ''){
      if(document.getElementById('emailAddress').value !== ''){
        if(document.getElementById('message').value !== ''){
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if(emailPattern.test(document.getElementById('emailAddress').value)){
            addDoc(collection(firestore,"Request"),{
              Name: document.getElementById('name').value,
              Email: document.getElementById('emailAddress').value,
              Message: document.getElementById('message').value
            }).then(()=>{
              Swal.fire({
                title: 'Success',
                text: 'Your request has been sent successfully.',
                confirmButtonText: 'Ok',
              }).then(()=>{
                document.getElementById('name').value = '';
                document.getElementById('emailAddress').value = '';
                document.getElementById('message').value = '';
              })
            })
          }else{
            Swal.fire({
              title: 'Error',
              text: 'Please enter a valid email address.',
              confirmButtonText: 'Ok',
            })
          }
        }else{
          Swal.fire({
            title: 'Error',
            text: 'Message is required.',
            confirmButtonText: 'Ok',
          })
        }
      }else{
        Swal.fire({
          title: 'Error',
          text: 'Email Address is required.',
          confirmButtonText: 'Ok',
        })
      }
    }else{
      Swal.fire({
        title: 'Error',
        text: 'Name is required.',
        confirmButtonText: 'Ok',
      })
    }
  }
    document.body.style.overflowX = "hidden";
  return (
    <div className="row" style={{marginLeft: '5%',marginRight: '5%',width: '90%',marginTop: '5%'}}>
    <div className="col-xs-12 col-sm-6">
    <div className="container py-4">

  <form id="contactForm">

    <div className="mb-3">
      <label className="form-label" htmlFor="name" style={{fontWeight:'bold'}}>Name</label>
      <input className="form-control" id="name" type="text" placeholder="Name" data-sb-validations="required" />
    </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="emailAddress" style={{fontWeight:'bold'}}>Email Address</label>
      <input className="form-control" id="emailAddress" type="email" placeholder="Email Address" data-sb-validations="required, email" />
    </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="message" style={{fontWeight:'bold'}}>Message</label>
      <textarea className="form-control" id="message" type="text" placeholder="Message" style={{height:'10rem'}} data-sb-validations="required"></textarea>
    </div>

    <div className="d-grid">
      <div className="btn btn-primary btn-lg" onClick={()=>{Submit()}}>Submit</div>
    </div>

  </form>

</div>

    </div>
    <div className="col-xs-12 col-sm-6" style={{textAlign:'center',marginTop:'5%'}}>
      <h2>
      STAR SOLUTIONS</h2>
<h6>GSTIN :- 18CUVPM8042F1Z2<br/>
UDYAM Reg. No. :- UDYAM-AS-03-0007741
</h6>
<h6>
<br/>
Contact: Niraj Maheswari <br/><br/>
House No 84, Maheswari Kunj,  <br/>
Bylane, 6, Barthakur Mill Rd,  <br/>
Ulubari, Guwahati,  <br/>
Assam, Pin-781007 <br/>
<br/>
</h6><h5>
Email:  info@starsolutions.biz
 <br/>
<br/>
Mob: 8133047257, 9864511425
      </h5>
    </div>
  </div>
  )
}
