import React from 'react'
import './Services.css'
export default function Products() {
  document.body.style.backgroundColor =''
  return (
    <>
    <div style={{marginTop: '2%',overflowX: 'hidden',marginLeft: '5%',marginRight: '5%',width: '90%'}}>
    <div className='row'>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">Desktop</h5>
  <div className='image-con'>
  <img src={"Images/des.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
<br/>
        </div>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">Laptop</h5>
  <div className='image-con'>
  <img src={"Images/lap.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
<br/>
        </div>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">Softwares</h5>
  <div className='image-con'>
  <img src={"Images/soft.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
<br/>
        </div>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">GPS Trackers</h5>
  <div className='image-con'>
  <img src={"Images/track.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
<br/>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">Printer</h5>
  <div className='image-con'>
  <img src={"Images/printer.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
<br/>
        </div>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">Attendance Solutions</h5>
  <div className='image-con'>
  <img src={"Images/attendance.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
<br/>
        </div>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">CCTV</h5>
  <div className='image-con'>
  <img src={"Images/cctv.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
<br/>
        </div>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">Consumer Electronics</h5>
  <div className='image-con'>
  <img src={"Images/ce.webp"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
        </div>
      </div>
    </div>
    <br/>
  </>
  )
}
