import React from 'react'

export default function Home() {
  document.body.style.backgroundColor =''
  return (
    <>
    <div className="home" style={{overflow:'hidden',background:'linear-gradient(to right, black, #004e92)',color:'white'}}>
            <div className="hero-container" id="hero-sec">
  <div className="container-fluid">
  <div className="row d-flex">
    <div className="col-md-6">
      <div>
      <img src={"Images/main.jpg"} className="img-fluid" alt="main" style={{width:'100%',height:'100%'}}/>
      </div>
    </div>
    <div className="col-md-6">
      <div className="px-5 py-5 mt-5">
        <div className="px-2 py-2 align-middle">
        <h3 style={{fontWeight:'bold',fontSize:'30px'}}>IT Solutions <br/>
Strategy<br/>
Development
</h3>
        </div>
        <div className="px-2 py-2">
          <button type="button" onClick={()=>{window.open("https://wa.me/918133047257")}} style={{color:'white'}} className="btn btn-success"><i className="fa fa-whatsapp" style={{color:'white',fontSize:'20px'}}></i>  Let's have a Chat</button>
        </div>
      </div>
    </div>
</div>
</div>
        </div>
        </div>
                  <div className="container text-center">
                  <footer className="py-3 my-4 border-top text-center">
                      <span className="mb-3 mb-md-0 text-body-secondary text-center">&copy; 2024 Star Solutions </span>
                  </footer>
                  </div>
            </>
  )
}
