import React from 'react'
import { Link } from 'react-router-dom'
export default function Navbar() {
function closenav(){
  document.getElementById('toggler').click()
}
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <Link className="navbar-brand" style={{fontWeight:'bold'}} to="/">Star Solutions</Link>
    <button id='toggler' className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarScroll">
      <ul className="navbar-nav ms-auto navbar-nav" >
        <li className="nav-item">
          <Link className="nav-link" to="/" onClick={()=>closenav()}>Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/products" onClick={()=>closenav()}>Products
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/services" onClick={()=>closenav()}>Services
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact" onClick={()=>closenav()}>Contact Us
          </Link>
        </li>
      </ul>
    </div>
  </div>
</nav>
</div>
  )
}
