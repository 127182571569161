import React from 'react'
import './Services.css'
export default function Services() {
  document.body.style.backgroundColor =''
  return (
    <>
    <div style={{marginTop: '2%',overflowX: 'hidden',marginLeft: '5%',marginRight: '5%',width: '90%'}}>
      <div className='row'>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">Laptop and Desktop Repair</h5>
  <div className='image-con'>
  <img src={"Images/Laptop_repair.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
<br/>
        </div>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">Data Recovery</h5>
  <div className='image-con'>
  <img src={"Images/Data_recovery.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
<br/>
        </div>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">CCTV Installation</h5>
  <div className='image-con'>
  <img src={"Images/cctv_install.webp"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
        </div>
        <div className='col-md-3'>
        <div className="card">
  <h5 className="card-header">Structured Cabling</h5>
  <div className='image-con'>
  <img src={"Images/cable.jpg"} className="w-100 image-hover" alt="Laptop Repair"/>
  </div>
</div>
        </div>
      </div>
    </div>
    <br/>
    <div className="container h-100">
    <div className="row align-items-center h-100">
      <div className="container rounded">
        <h3 className="text-center">Brands:</h3>
        <div className="slider">
          <div className="logos">
          <i className="fab"><img alt='HP' src='Images/hp.png' width={100} height={100}/></i>
          <i className="fab"><img alt='Lenevo' src='Images/lenovo.png' width={140} height={100}/></i>
          <i className="fab"><img alt='Acer' src='Images/acer.webp' width={140} height={100}/></i>
          <i className="fab"><img alt='Samsung' src='Images/sam.png' width={140} height={100}/></i>
          <i className="fab"><img alt='Asus' src='Images/asus.png' width={140} height={100}/></i>
          <i className="fab"><img alt='Dell' src='Images/dell.png' width={100} height={100}/></i>
          </div>
        </div>
      </div>
    </div>
  
  </div>
  </>
  )
}
